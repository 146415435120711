  #profileContainer .form-change-password,
  #profileContainer .form-change-password button {
    font-size: 0.9rem;
 }

 #profileContainer label,
 #profileContainer input   {
   font-size: 0.9rem;
 }

 #profileContainer input {
   max-width: 300px;
 }

 #profileContainer .invalid-feedback {
   font-size: 0.7rem;
 }

 #profileContainer h4 {
   font-size: 1rem;
 }

 #profileContainer .profile-text {
  font-size: 0.9rem;
  margin: 5px;
}